import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Form from "./form"

const FormSection = () => {
  const { formImage } = useStaticQuery(graphql`
    {
      formImage: file(
        relativePath: {
          eq: "homePage/form/allef-vinicius-nHoaFkrJEx4-unsplash.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="d-md-none">
        <div className="bg-light">
          <div className="container">
            <div
              className="position-relative w-100"
              style={{ paddingTop: "56.25%" }}
            >
              <Img
                className="position-absolute w-100"
                style={{ top: "0", left: "0", maxHeight: "100%" }}
                fluid={formImage.childImageSharp.fluid}
              />
            </div>
            <div
              className="pt-3 px-2 pb-3"
              style={{
                background: "linear-gradient(to right, #0072ff, #00c6ff)",
              }}
            >
              <h3 className="text-light font-weight-light">
                Tertarik untuk Belajar Ke Luar Negeri?
              </h3>
              <p className="text-light font-weight-light">
                Isi informasi tentang anda dan kami akan menghubungi anda saat
                kesempatan emas anda muncul
              </p>
            </div>
            <div className="bg-light p-3">
              <Form />
              <div className="my-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-md-block">
        <div className="bg-light">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-6">
                <div
                  className="position-relative w-100"
                  style={{ paddingTop: "66%" }}
                >
                  <Img
                    className="position-absolute w-100"
                    style={{ top: "0", left: "0", maxHeight: "100%" }}
                    fluid={formImage.childImageSharp.fluid}
                  />
                </div>
              </div>
              <div className="col-6">
                <div
                  className="pt-3 px-4 pb-3 h-100 d-flex flex-column justify-content-center"
                  style={{
                    background: "linear-gradient(to right, #0072ff, #00c6ff)",
                  }}
                >
                  <h3 className="text-light font-weight-light">
                    Tertarik untuk Belajar Ke Luar Negeri?
                  </h3>
                  <p className="text-light font-weight-light">
                    Isi informasi tentang anda dan kami akan menghubungi anda
                    saat kesempatan emas anda muncul
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-light p-3">
              <div className="my-5" />
              <Form />
              <div className="my-5" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FormSection
