import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const OurPartnersSection = () => {
  const { partnerLogos } = useStaticQuery(graphql`
    {
      partnerLogos: allFile(
        filter: { relativeDirectory: { eq: "homePage/ourPartners" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fixed(
                width: 150
                height: 150
                fit: CONTAIN
                background: "white"
              ) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  const partners = partnerLogos.edges.map(({ node }) => {
    return (
      <Img
        className="mx-2"
        fixed={node.childImageSharp.fixed}
        key={`${node.id}`}
      />
    )
  })

  return (
    <React.Fragment>
      <div className="container">
        <h2 className="text-center font-weight-light">Our Partners</h2>
        <div className="d-flex flex-wrap justify-content-center">
          {partners}
        </div>
      </div>
    </React.Fragment>
  )
}
export default OurPartnersSection
