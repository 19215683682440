import React, { useState } from "react"
import Select from "react-select"

const negaraImpian = [
  { value: "Australia", label: "Australia" },
  { value: "Amerika Serikat", label: "Amerika Serikat" },
  { value: "Belanda", label: "Belanda" },
  { value: "China", label: "China" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Inggris", label: "Inggris" },
  { value: "Kanada", label: "Kanada" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Selandia Baru", label: "Selandia Baru" },
  { value: "Singapura", label: "Singapura" },
  { value: "Swiss", label: "Swiss" },
]

const Form = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [countryCode, setCountryCode] = useState(62)
  const [telephone, setTelephone] = useState(0)
  const [countryOfChoice, setCountryOfChoice] = useState("")

  function handleSubmit(e) {
    e.preventDefault()
    alert(
      JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: email,
        countryCode: countryCode,
        telephone: telephone,
        countryOfChoice: countryOfChoice,
      }) +
        "\n" +
        "\n" +
        "If you would like to do more of this, please discuss with sawir.ricardo@gmail.com of how we can work together for this."
    )
  }

  function handleCountryOfChoice(choice) {
    setCountryOfChoice(choice)
  }

  return (
    <React.Fragment>
      <form onSubmit={e => handleSubmit(e)}>
        <div className="row">
          <div className="col-12 col-md-6 mt-5 mt-md-0">
            <div style={{ borderBottom: "2px solid #999999" }}>
              <input
                type="text"
                name="First name"
                onChange={e => setFirstName(e.target.value)}
                className="border-0 bg-light w-100"
                placeholder="First Name*"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 mt-5 mt-md-0">
            <div style={{ borderBottom: "2px solid #999999" }}>
              <input
                type="text"
                name="Last name"
                onChange={e => setLastName(e.target.value)}
                className="border-0 bg-light w-100"
                placeholder="Last Name*"
              />
            </div>
          </div>
        </div>
        <div className="my-5" />
        <div style={{ borderBottom: "2px solid #999999" }}>
          <input
            type="email"
            name="email"
            onChange={e => setEmail(e.target.value)}
            className="border-0 bg-light w-100"
            placeholder="Email*"
          />
        </div>
        <div className="my-5" />
        <div className="row">
          <div className="col-3 col-md-3">
            <div style={{ borderBottom: "2px solid #999999" }}>
              <input
                type="number"
                name="code number"
                onChange={e => setCountryCode(e.target.value)}
                className="border-0 bg-light w-100"
                value={countryCode}
              />
            </div>
          </div>
          <div className="col-9 col-md-9">
            <div style={{ borderBottom: "2px solid #999999" }}>
              <input
                type="number"
                name="code number"
                onChange={e => setTelephone(e.target.value)}
                className="border-0 bg-light w-100"
                placeholder="8128888xxxx"
              />
            </div>
          </div>
        </div>
        <div className="my-5" />
        <div style={{ borderBottom: "2px solid #999999" }}>
          <Select
            onChange={choice => handleCountryOfChoice(choice.value)}
            options={negaraImpian}
            className="selector"
            placeholder="Negara Impianmu"
          />
        </div>
        <div className="my-5" />
        <div className="text-center">
          <button type="submit" className="btn btn-green px-5 text-light">
            Saya Mau Belajar Ke Luar Negeri
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Form
