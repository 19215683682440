import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import MobileMenuView from "./mobileMenu"
import { MdMenu } from "react-icons/md"

const Navbar = ({ menuState, setMenu }) => {
  const { navigateTo } = useStaticQuery(graphql`
    {
      navigateTo: site {
        siteMetadata {
          link {
            id
            name
            to
          }
        }
      }
    }
  `)
  const links = navigateTo.siteMetadata.link.map(link => {
    return (
      <div key={`${link.id}`} className="col text-center">
        <Link
          to={link.to}
          className="text-decoration-none text-light font-weight-light lead"
        >
          {link.name}
        </Link>
      </div>
    )
  })

  return (
    <React.Fragment>
      <nav className="d-none d-lg-block">
        <div className="bg-dark py-3">
          <div className="container">
            <div className="row">{links}</div>
          </div>
        </div>
      </nav>
      <nav className="d-lg-none">
        <div className="bg-dark py-3">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <Link
                to="/service"
                className="text-decoration-none text-light font-weight-light lead"
              >
                Services
              </Link>
              <button className="text-light btn m-0 p-0" onClick={setMenu}>
                <MdMenu size={"2rem"} />
              </button>
            </div>
          </div>
        </div>
        {menuState && <MobileMenuView onCloseMenu={setMenu} />}
      </nav>
    </React.Fragment>
  )
}
export default Navbar
