import React from "react"
import { Link } from "gatsby"
import university from "../../images/homePage/firstSection/university.svg"
import mortarboard from "../../images/homePage/firstSection/mortarboard.svg"
import checklist from "../../images/homePage/firstSection/checklist.svg"

const FirstSection = () => {
  return (
    <React.Fragment>
      <div className="bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <img
                src={university}
                style={{ width: "auto", height: "100px" }}
              />
              <div className="my-3" />
              <h2>Ingin Belajar ke Luar Negeri?</h2>
              <p className="text-muted">
                Terdapat beberapa persiapan yang perlu kamu lakukan supaya
                jenjang studimu dapat kamu lewati dengan baik
              </p>
              <Link to="/service/preparing-to-study-overseas">
                Cari tahu persiapannya
              </Link>
            </div>
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <img
                src={mortarboard}
                style={{ width: "auto", height: "100px" }}
              />
              <div className="my-3" />
              <h2>Kenapa Sekolah ke Luar Negeri?</h2>
              <p className="text-muted">
                Pengalaman belajar dan tinggal di luar negeri akan memberikan
                banyak keuntungan dan nilai tambah dalam menghadapi persaingan
                dunia kerja yang semakin kompetitif dan dunia usaha yang semakin
                mengglobal.
              </p>
              <Link to="/service/preparing-to-study-overseas">
                Cari tahu lebih lanjut
              </Link>
            </div>
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <img src={checklist} style={{ width: "auto", height: "100px" }} />
              <div className="my-3" />
              <h2>Pengurusan Studi Sangat Mudah</h2>
              <p className="text-muted">
                Dengan mengikuti 8 EdSteps, kamu bisa segera mendapatkan
                universitas pilihanmu di luar negeri.
              </p>
              <Link to="/service/preparing-to-study-overseas">
                Cari tahu lebih lanjut
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FirstSection
