import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage/heroImage"
import FirstSection from "../components/homePage/firstSection"
import OurPartnersSection from "../components/homePage/ourPartners"
import StoriesOfOurStudents from "../components/homePage/stories"
import NewsUpdateSection from "../components/homePage/newsSection"
import FormSection from "../components/homePage/formSection"

const IndexPage = () => {
  return (
    <React.Fragment>
      <SEO title="Home" />
      <Layout>
        <HeroImage />

        <FirstSection />
        <div className="my-5" />
        <OurPartnersSection />
        <div className="my-5" />
        <StoriesOfOurStudents />
        <div className="my-5" />
        <NewsUpdateSection />
        <div className="my-5" />
        <FormSection />
      </Layout>
    </React.Fragment>
  )
}
export default IndexPage
