import React, { useState } from "react"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Navbar from "./navbar/navbar"
import Footer from "./footer/footer"

const Layout = ({ children }) => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "edlinkeducation-logo.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  const [isMenuMobileOpen, setMenuMobileOpen] = useState(false)
  function handleNavbarMobile() {
    setMenuMobileOpen(!isMenuMobileOpen)
  }
  return (
    <React.Fragment>
      <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
        <div className="mx-auto py-3 ">
          <Link to="/">
            <Img fixed={logo.childImageSharp.fixed} alt="Edlink Logo" />
          </Link>
        </div>
        <Navbar menuState={isMenuMobileOpen} setMenu={handleNavbarMobile} />
        {!isMenuMobileOpen && children}

        <div className="flex-grow-1" />
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Layout
