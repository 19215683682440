import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const StoriesOfOurStudents = () => {
  const { studentStories, studentTestimony } = useStaticQuery(graphql`
    {
      studentStories: allFile(
        filter: { relativeDirectory: { eq: "homePage/kisahMurid" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(width: 480, height: 270) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      studentTestimony: allFile(
        filter: { relativeDirectory: { eq: "homePage/kisahMurid/testimony" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed(width: 150, height: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const students = studentStories.edges.map(({ node }) => {
    return (
      <div className="col-12 col-md-4 mt-5 mt-md-0" key={`${node.id}`}>
        <Link
          className="text-decoration-none"
          to={`/testimony/${node.name.replace(/ /g, "-")}`}
        >
          <div
            className="position-relative w-100"
            style={{ paddingTop: "56.26%" }}
          >
            <Img
              className="position-absolute w-100"
              style={{ top: "0", left: "0", height: "100%" }}
              fixed={node.childImageSharp.fixed}
            />
          </div>
          <div className="mt-3" />
          <h5 className="font-weight-light">{node.name}</h5>
        </Link>
      </div>
    )
  })

  return (
    <React.Fragment>
      <div className="py-5 bg-light">
        <div className="container">
          <h2 className="font-weight-light text-center">
            Kisah Tentang Murid Kami
          </h2>
          <div className="my-5" />
          <div className="row">{students}</div>
          <div className="my-5" />
          <div className="text-center">
            <Link className="text-decoration-none" to="/testimony">
              <button className="btn btn-blue px-5 text-light">
                Lihat Lebih Banyak
              </button>
            </Link>
          </div>
        </div>
        <div className="py-5" />
        <div className="container">
          <h2 className="font-weight-light text-center">Apa kata mereka</h2>
          <div className="my-5" />
          <div className="row">
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <Img
                className="rounded-circle"
                fixed={studentTestimony.edges[0].node.childImageSharp.fixed}
              />
              <div className="mt-3" />
              <h6>{studentTestimony.edges[0].node.name}</h6>
              <p className="text-muted small font-italic">
                NSW Government School (year 10-12)
              </p>
              <p className=" font-weight-light">
                Kelas persiapan IELTS di Edlink+ConneX Surabaya Barat sangat
                bagus! Gurunya ramah dan mengajarkan materi dengan baik dan
                jelas sehingga saya sebagai murid sangat menikmati kelas…{" "}
              </p>
            </div>
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <Img
                className="rounded-circle"
                fixed={studentTestimony.edges[1].node.childImageSharp.fixed}
              />
              <div className="mt-3" />
              <h6>{studentTestimony.edges[1].node.name}</h6>
              <p className="text-muted small font-italic">
                William Angliss, Australia
              </p>
              <p className=" font-weight-light">
                Saya sungguh senang bekerja sebagai koki pasta di sebuah
                restoran Italia terkenal di Melbourne. Di sini saya bertemu
                dengan banyak sekali chef terkenal yang luar biasa dan
                memperoleh banyak pengalaman berharga di dapur restoran.
              </p>
            </div>
            <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
              <Img
                className="rounded-circle"
                fixed={studentTestimony.edges[2].node.childImageSharp.fixed}
              />
              <div className="mt-3" />
              <h6>{studentTestimony.edges[2].node.name}</h6>
              <p className="text-muted small font-italic">
                University of Western Australia
              </p>
              <p className=" font-weight-light">
                Profesionalisme mereka telah membantu saya sepanjang proses
                belajar ke luar negeri saya: dari tahapan konsultasi di
                Surabaya, sepuluh bulan saya mengikuti program Uni-Bridge di
                Bogor, kemudian studi lanjutan di Tuart College, Perth dan
                akhirnya sekarang di University of Western Australia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default StoriesOfOurStudents
