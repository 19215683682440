import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { MdClose } from "react-icons/md"

const MobileMenuView = ({ onCloseMenu }) => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "edlinkeducation-logo.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <div
        className="position-absolute bg-light"
        style={{ top: "0", left: "0", right: "0", bottom: "0", zIndex: "2" }}
      >
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between py-3">
            <Img className="ml-3" fixed={logo.childImageSharp.fixed} />
            <button className="text-dark btn" onClick={onCloseMenu}>
              <MdClose size={"3rem"} />
            </button>
          </div>
          <div className="py-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h2>Services</h2>
              </li>
              <li className="list-group-item">Syarat dan Pendaftaran</li>
              <li className="list-group-item">
                Kenapa Sekolah Ke Luar Negeri?
              </li>
              <li className="list-group-item">
                Persiapan Belajar Ke Luar Negeri
              </li>
              <li className="list-group-item">
                Belajar dan Tinggal di Luar Negeri
              </li>
              <li className="list-group-item">8 Easy EdSteps</li>
            </ul>
          </div>
          <div className="py-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h2>IELTS</h2>
              </li>
            </ul>
          </div>
          <div className="py-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h2>Berita</h2>
              </li>
            </ul>
          </div>
          <div className="py-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h2>Kegiatan</h2>
              </li>
              <li className="list-group-item">Interview Sessions</li>
              <li className="list-group-item">Pameran</li>
              <li className="list-group-item">Tes IELTS</li>
              <li className="list-group-item">PDB</li>
              <li className="list-group-item">Kompetisi</li>
              <li className="list-group-item">Seminar</li>
            </ul>
          </div>
          <div className="py-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h2>Tujuan</h2>
              </li>
              <li className="list-group-item">All</li>
              <li className="list-group-item">Amerika Serikat</li>
              <li className="list-group-item">Australia</li>
              <li className="list-group-item">Belanda</li>
              <li className="list-group-item">China</li>
              <li className="list-group-item">Indonesia</li>
              <li className="list-group-item">Inggris</li>
              <li className="list-group-item">Kanada</li>
              <li className="list-group-item">Malaysia</li>
              <li className="list-group-item">Selandia Baru</li>
              <li className="list-group-item">Singapura</li>
              <li className="list-group-item">Swiss</li>
            </ul>
          </div>
          <div className="py-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h2>Testimoni</h2>
              </li>
              <li className="list-group-item">Murid</li>
              <li className="list-group-item">Orang Tua</li>
              <li className="list-group-item">Konsultan</li>
            </ul>
          </div>
          <div className="py-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h2>Galeri</h2>
              </li>
              <li className="list-group-item">Foto</li>
              <li className="list-group-item">Video</li>
            </ul>
          </div>
          <div className="py-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h2>Enquiries</h2>
              </li>
            </ul>
          </div>
          <div className="my-5" />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MobileMenuView
