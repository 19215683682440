import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const NewsUpdateSection = () => {
  const { news } = useStaticQuery(graphql`
    {
      news: allFile(filter: { relativeDirectory: { eq: "homePage/berita" } }) {
        edges {
          node {
            id
            name
            accessTime(formatString: "MMMM Do, YYYY")
            childImageSharp {
              fixed(width: 480, height: 270) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  const newsContents = news.edges.map(({ node }) => {
    return (
      <div key={`${node.id}`} className="col-12 col-md-4 mt-5 mt-md-0">
        <Link
          className="text-decoration-none"
          to={`/news/${node.name.replace(/ /g, "-")}`}
        >
          <div
            className="position-relative w-100"
            style={{ paddingTop: "56.25%" }}
          >
            <Img
              className="position-absolute w-100"
              style={{ top: "0", left: "0", height: "100%" }}
              fixed={node.childImageSharp.fixed}
            />
          </div>
          <div className="mt-3" />
          <h5 className="font-weight-light">{node.name}</h5>
          <div className="mt-3" />
          <p className="small text-muted">{node.accessTime}</p>
        </Link>
      </div>
    )
  })
  return (
    <React.Fragment>
      <div className="container">
        <h2 className="font-weight-light text-center">Berita + Update</h2>
        <div className="mt-5" />
        <div className="row">{newsContents}</div>
        <div className="mt-5" />
        <div className="text-center">
          <button className="btn btn-blue px-5 text-light">
            Lihat Bacaan Lainnya
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewsUpdateSection
