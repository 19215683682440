import React from "react"
import { useMediaQuery } from "react-responsive"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const HeroImage = () => {
  const { mobileImage, desktopImage } = useStaticQuery(graphql`
    {
      mobileImage: file(
        relativePath: { eq: "homePage/heroImage/A Girl studying abroad.jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 450, cropFocus: NORTH) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      desktopImage: file(
        relativePath: { eq: "homePage/heroImage/A Girl studying abroad.jpg" }
      ) {
        childImageSharp {
          fixed(width: 1200, height: 400, cropFocus: NORTH) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="d-lg-none">
        <div
          className="position-relative w-100 bg-light"
          style={{ paddingTop: "56.25%" }}
        >
          <Img
            className="position-absolute"
            style={{
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              transform: "scaleX(-1)",
              WebkitTransform: "scaleX(-1)",
            }}
            fixed={mobileImage.childImageSharp.fixed}
          />
        </div>
        <div className="container text-center py-5 my-5">
          <h1 className=" font-weight-light" style={{ color: "#121B91" }}>
            Ingin Belajar ke Luar Negeri?
          </h1>
          <div className="my-4" />
          <Link to="/service">
            <button className="btn btn-orange px-5 text-light">
              Register Now
            </button>
          </Link>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="position-relative w-100 bg-light"
          style={{ paddingTop: "33.33%" }}
        >
          <Img
            className="position-absolute w-100"
            style={{
              top: "0",
              left: "0",
              height: "100%",
              filter: "brightness(75%)",
              transform: "scaleX(-1)",
              WebkitTransform: "scaleX(-1)",
            }}
            fixed={desktopImage.childImageSharp.fixed}
          />

          <div
            className=" w-100 position-absolute"
            style={{ top: "0", left: "0", right: "0", bottom: "0" }}
          >
            <div className="container d-flex flex-column justify-content-center align-items-start h-100">
              <h1 className="text-light">Ingin Belajar ke Luar Negeri?</h1>
              <div className="my-2" />
              <Link to="/service">
                <button className="btn btn-orange text-light px-5">
                  Register Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default HeroImage
