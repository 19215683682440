import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <React.Fragment>
      <nav className="bg-dark py-5">
        <div className="container text-light">
          <div className="my-3" />
          <div className="row">
            <div className="col-12 col-md-4">
              <h6 className="font-weight-light small flex-fill text-light">
                Copyright ©{new Date().getFullYear().toString()} Edlink+ConneX -
                International Education Consultancy Group
              </h6>
            </div>
            <div className="col-12 col-md-4 text-left text-md-center">
              <div className="font-weight-light small">
                <Link className="text-light text-decoration-none " to="/term">
                  Term of Use
                </Link>{" "}
                |{" "}
                <Link className="text-light text-decoration-none" to="/privacy">
                  Privacy Statement
                </Link>
              </div>
            </div>
          </div>
          <div className="my-5" />
          <p className="small font-weight-light">
            Build with{" "}
            <a
              href="https://website.sawirstudio.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              &lt;Sawir Studio/&gt;
            </a>
          </p>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Footer
